<template>
  <validation-observer
    ref="assessmentObserver"
    v-slot="{ invalid }"
  >
    <v-stepper-content
      step="2"
      class="assesment-step"
    >
      <div v-if="parent.optionsStep === 1">
        <v-row class="justify-center mt-4">
          <v-col
            cols="11"
            sm="10"
            class="text-center pt-2"
          >
            <v-card
              elevation="10"
              class="pa-6 assessment-card pale-blue mt-0"
            >
              <div class="estimate-title secondary--text text-h2 font-weight-bold mb-8">
                {{ $t('caTerm.howMuch') }}
              </div>

              <validation-observer
                ref="assessmentFormWrapper"
                v-slot="{invalid}"
              >
                <v-form
                  ref="assessmentForm"
                  v-model="parent.valid"
                  @submit.prevent="getRates"
                >
                  <v-row
                    v-for="field in parent.calculatorFields"
                    :key="field.model"
                    class="d-flex align-center mt-2"
                  >
                    <v-col
                      v-if="!parent.mobileDevice"
                      cols="4"
                      class="text-h4 secondary--text pt-0"
                    >
                      {{ $t(field.label) }}
                    </v-col>

                    <v-col
                      cols="11"
                      sm="4"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{
                          required:field.model ==='annualIncome',
                          range:[field.model!=='annualIncome'?0:0,9999999,true]
                        }"
                      >
                        <v-text-field
                          :data-test-id="field.model"
                          :value="getFormattedValue(field.model)"
                          :label="parent.mobileDevice ? $t(field.label) : ''"
                          class="mb-n4"
                          outlined
                          dense
                          color="secondary"
                          :error-messages="errors"
                          :name="field.model"
                          @input="setFormattedValue($event, field.model)"
                          @change="checkNeedForAssessment(field.model)"
                          @keydown="restrictInput($event,field.model )"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="assessedInsuranceNeed"
                    class="d-flex align-center mt-2"
                  >
                    <v-col
                      v-if="!parent.mobileDevice"
                      cols="4"
                      class="text-h4 secondary--text font-weight-bold pt-0"
                    >
                      Total
                    </v-col>
                    <v-col
                      cols="11"
                      sm="4"
                    >
                      <p class="text-h2 secondary--text font-weight-bold">
                        {{ parent.assessedInsuranceNeedFormatted }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row v-if="parent.greaterInsuranceNeed">
                    <v-col>
                      <p
                        v-if="parent.greaterInsuranceNeed"
                        class="secondary--text text-center text-h4 my-4"
                        v-html="$t('caTerm.assessmentNeeds.greaterInsuranceNeed')
                          .replace('%%need%%',`<b>${parent.assessedInsuranceNeedFormatted}</b>`).replace('%%upto%%',`<b>${parent.formattedCoverageAmount}</b>`)"
                      >
                      </p>
                      <p
                        v-if="parent.noInsuranceNeed"
                        v-html="parent.noInsuranceNeedMessage"
                      >
                      </p>
                    </v-col>
                  </v-row>
                </v-form>
              </validation-observer>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="pa-2 ma-2 d-flex justify-center">
          <v-btn
            x-large
            elevation="6"
            class="white--text font-weight-bold style-back"
            @click="parent.back"
          >
            {{ $t('common.buttons.back') }}
          </v-btn>
          <v-btn
            x-large
            class="font-weight-bold"
            color="primary"
            :disabled="invalid || assessedInsuranceNeed === null"
            @click="getUpdatedRates"
          >
            {{ $t('common.buttons.continue') }}
          </v-btn>
        </v-row>
      </div>
      <div v-if="parent.optionsStep === 2">
        <v-card
          min-height="300"
          elevation="0"
        >
          <div class="estimate-title primary--text mb-12">
            {{ $t('caTerm.premiumQuote.premiumQuote') }}
          </div>
          <div
            v-if="!parent.loading && parent.termLengths"
            class="px-0"
          >
            <p class="estimate-subtitle">
              {{ $t('caTerm.premiumQuote.yourQuote') }}
              <span
                class="font-weight-bold primary--text"
              > {{ parent.monthlyPremium }} /{{ $t('common.month') }}*</span>
            </p>
            <v-row class="d-flex justify-center">
              <v-col>
                <p style="font-weight: bold;text-align: center">
                  {{ $t('confirmCoverage') }}
                </p>
              </v-col>
            </v-row>
            <v-row class="d-felx justify-center">
              <v-col
                id="termDropdown"
                cols="11"
                sm="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Term"
                  rules="required"
                >
                  <v-select
                    v-model="parent.user.term"
                    :items="parent.allowedTermLengths"
                    outlined
                    :label="$t('caTerm.approval.term')"
                    color="primary"
                    :error-messages="errors"
                    @change="fetchRates"
                  ></v-select>
                </validation-provider>
              </v-col>
              <v-col
                id="coverageDropdown"
                cols="11"
                sm="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Coverage"
                  rules="required"
                >
                  <v-select
                    v-model="parent.user.coverage"
                    :items="parent.coverageAmounts"
                    item-text="text"
                    item-value="value"
                    outlined
                    :label="$t('caTerm.premiumQuote.coverageAmount')"
                    color="primary"
                    :error-messages="errors"
                    @change="fetchRates"
                  ></v-select>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col
                cols="11"
                sm="10"
                md="10"
              >
                <v-card
                  elevation="0"
                  class="text-center plan-card pa-3 mx-0"
                >
                  <div
                    class="d-inline-flex align-center justify-center mt-2 mb-2"
                  >
                    <div class="switch-text ml-2">
                      {{ $t('caTerm.premiumQuote.policyOptions') }}
                    </div>
                  </div>
                  <v-row class="d-flex align-center mt-2">
                    <v-col
                      cols="4"
                      class="px-0"
                    >
                      <span
                        :class="
                          parent.mobileDevice
                            ? 'offer-text-mobile'
                            : 'offer-text'
                        "
                      >
                        <b>{{ $t('caTerm.premiumQuote.premium') }}</b>
                      </span>
                      <br />
                      <v-chip
                        class="primary--text mt-4"
                        outlined
                        color="primary"
                      >
                        <span
                          :class="
                            parent.mobileDevice
                              ? 'chip-text-mobile'
                              : 'chip-text'
                          "
                        >{{ parent.monthlyPremium }}</span>
                      </v-chip>
                    </v-col>
                    <v-col
                      cols="4"
                      class="px-0"
                    >
                      <div
                        :class="
                          parent.mobileDevice
                            ? 'offer-text-mobile'
                            : 'offer-text'
                        "
                      >
                      <b>{{ $t('caTerm.premiumQuote.termLength') }}</b>
                      </div>
                      <v-chip
                        class="gray--text mt-4"
                        outlined
                        color="primary"
                      >
                        <span
                          :class="
                            parent.mobileDevice
                              ? 'chip-text-mobile'
                              : 'chip-text'
                          "
                        >{{ parent.CATermLifeTerm }} {{ $t('common.years') }}</span>
                      </v-chip>
                    </v-col>

                    <v-col
                      cols="4"
                      class="px-0"
                    >
                      <div
                        :class="
                          parent.mobileDevice
                            ? 'offer-text-mobile'
                            : 'offer-text'
                        "
                      >
                        <b>{{ $t('caTerm.premiumQuote.faceAmount') }}</b>
                      </div>
                      <v-chip
                        class="gray--text mt-4"
                        outlined
                        color="primary"
                      >
                        <span
                          :class="
                            parent.mobileDevice
                              ? 'chip-text-mobile'
                              : 'chip-text'
                          "
                        >{{ parent.formattedCoverageAmount }}</span>
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

              <v-col
                cols="11"
                class="text-center subtitle black--text mt-6 mb-2 font-16"
              >
                {{ $t('caTerm.premiumQuote.disclaimer') }}
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-row class="pa-2 ma-2 d-flex justify-center">
          <v-btn
            x-large
            class="white--text font-weight-bold style-back"
            @click="parent.optionsStep = 1; parent.scrollToTop()"
          >
            {{ $t('common.buttons.back') }}
          </v-btn>
          <v-btn
            x-large
            class="font-weight-bold"
            color="primary"
            :disabled="invalid"
            @click="parent.nextquestion('policyOptions')"
          >
            {{ $t('common.buttons.continue') }}
          </v-btn>
        </v-row>
      </div>
    </v-stepper-content>
  </validation-observer>
</template>

<script>
  import { getDefaultValue } from '@/utils/common'
  import moment from 'moment'
  export default {
    props: ['parent'],
    computed: {
      getPayLoad () {
        return {
          userKey: this.parent.userkey,
          grossIncome: getDefaultValue(this.parent.annualIncome),
          grossHouseholdIncome: getDefaultValue(this.parent.grossHouseholdAnnualIncome),
          savings: getDefaultValue(this.parent.savings),
          mortgageBalance: getDefaultValue(this.parent.mortgageBalance),
          loanDebts: getDefaultValue(this.parent.loansAndDebts),
          dob: moment(this.parent.user.dob, 'MM/DD/YYYY').format('YYYY-MM-DD')
        }
      },
      assessedInsuranceNeed () {
        return this.$store.getters.getProperty('CATermInsuranceNeed')
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.$emit('step-ref', this)
      })
    },
    methods: {
      async setFormattedValue (value, model) {
        await this.$refs.assessmentFormWrapper.validate()
        this.parent[model] = isNaN(parseFloat(value.replace(/[^0-9.]/g, ''))) ? 0 : parseFloat(value.replace(/[^0-9.]/g, ''))
      },
      async checkNeedForAssessment (val) {
        const defaultValues = ['annualIncome', 'savings', 'mortgageBalance', 'loansAndDebts']
        this.parent.isCalenderClicked = true
        const getValue = this[val] || val
        if (getValue && defaultValues.includes(getValue) && this.parent.annualIncome) {
          await this.$store.dispatch('CATermNeedsAssessment', this.getPayLoad)
          this.parent.isCalenderClicked = false
          // await this.parent.updateUserJourney()
        }
      },
      restrictInput (event, value) {
        this.$nextTick(() => {
          const currentVal = event?.target?._value?.replace(/\$|,/g, '') || ''
          if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'Tab') {
            return
          }
          if (!/^\d$/.test(event.key)) {
            event.preventDefault()
          }
          if (value === 'annualIncome') {
            if (currentVal === '' && event.key === '0') {
              event.preventDefault()
            }
            if (currentVal === '0') {
              event.preventDefault()
            }
          }
        })
      },
      getFormattedValue (model) {
        const rawValue = this.parent[model]
        if (!rawValue) return model = null
        const FORMAT_OPTIONS = { maximumFractionDigits: 0, style: 'currency', currency: 'USD' }
        return parseFloat(rawValue).toLocaleString('en-US', FORMAT_OPTIONS)
      },
      async getUpdatedRates () {
        try {
          await this.$store.dispatch('getCATermPremium', this.parent.premiumPayload)
          this.parent.optionsStep++
        } catch (e) {
        }
      },
      async getRates () {
        try {
          if (!await this.validateForms()) {
            return
          }
          await this.fetchRates()
          // Moved the condition inside the try block for better readability
          if (!this.parent.errorMessage) {
            this.parent.advanceToNextStep()
          } else {
            this.parent.scrollToError()
          }
        } catch (error) {
        }
      },
      async validateForms () {
        try {
          const [assessmentObserverResult, assessmentFormResult] = await Promise.all([
            this.$refs.assessmentObserver.validate(),
            this.$refs.assessmentForm.validate()
          ])

          return assessmentObserverResult && assessmentFormResult
        } catch (error) {
          return false
        }
      },
      async fetchRates () {
        await this.$store.dispatch('getCATermPremium', this.parent.premiumPayload)
      }
    }
  }
</script>

<style lang="scss">
@import "@/sass/caterm/assessment.scss";
.font-16{
  font-size:16px;
}
</style>
