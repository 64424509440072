<template>
  <v-stepper-content
    step="7"
    :class="parent.mobileDevice ? 'pa-0' : ''"
  >
    <div
      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
    >
      {{ $t('caTerm.payments.payment') }}
    </div>
    <v-row class="d-flex justify-center">
      <v-col class=" text-center text-h3 primary--text font-weight-bold">
        {{ $t('caTerm.payments.amountDue') }}: {{ finalPremium }}
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center text-center px-2">
      <v-col
        cols="11"
        class="d-flex justify-center align-center"
      >
        <p class="text-h4 secondary--text font-weight-bold mr-2">
          {{ $t('caTerm.payments.selectPaymentType') }}
        </p>
        <v-radio-group
          v-model="parent.paymethod"
          row
        >
          <v-radio
            v-for="(item) in parent.paymentOptions"
            :key="item.value"
            color="blanketbutton"
            text
            outlined
            :value="item.value"
            :name="item.value"
          >
            <template v-slot:label>
              <span class="radio-icon style-icon">
                <v-icon class="primary--text dash-icons">{{ item.icon }}</v-icon>
              </span>
              <span>{{ item.label }}</span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row
      v-if="parent.paymethod === 'creditcard'"
      class="d-flex justify-center"
    >
      <v-col
        cols="11"
        sm="6"
      >
        <b-credit-card @make-payment="updateCardDetails" />
      </v-col>
    </v-row>
    <v-row
      v-if="parent.paymethod === 'ach'"
      class="d-flex justify-center"
    >
      <v-col
        cols="11"
        sm="6"
      >
        <b-banking-form
          :payment-frequency="parent.paymentFrequency"
          :premium-amount="parent.paymentFrequency==='monthly'?parent.formattedMonthlyTotal:parent.formattedAnnualTotal"
          @make-bank-payment="makeBankPayment"
        />
      </v-col>
    </v-row>
    <v-row class="pa-2 ma-2 d-flex justify-center">
      <v-btn
        x-large
        class="white--text font-weight-bold style-back"
        :disabled="parent.loading"
        @click="parent.back"
      >
        {{ $t('common.buttons.back') }}
      </v-btn>
    </v-row>
    <v-row
      v-if="parent.time"
      class="mt-12"
    >
      <v-col class="d-flex justify-center">
        <ellipse-progress
          :loading="parent.time"
          :size="180"
          img-src="src/assets/images/termlife-icon.png"
          thickness="5"
          empty-thickness="3"
          line-mode="out 5"
          legend="false"
          animation="rs 700 1000"
          font-size="1.5rem"
          color="#00A1B7"
          empty-color-fill="transparent"
          filename="termlife-icon.png"
        >
        </ellipse-progress>
      </v-col>
    </v-row>
  </v-stepper-content>
</template>

<script>
  import i18n from '@/i18n'
  import { localCurrency } from '@/utils/common'
  import { formatCurrency } from '@/i18nValidate'
  import BCreditCard from '../../components/TermLifeCA/BCreditCard.vue'
  import BBankingForm from '../../components/TermLifeCA/BBankingForm.vue'
  import { validateACH, validateCC } from '../../../../plugins/chargeover'
  import { gtag_report_conversion } from '../../../../utils/common'
  export default {
    components: {
      BCreditCard,
      BBankingForm,
    },
    props: ['parent'],
    computed: {
      finalPremium () {
        return this.parent.paymentFrequency === 'monthly' ? this.parent.formattedMonthlyTotal : this.parent.formattedAnnualTotal
      }
    },
    methods: {
      async navigateToPayment () {
        try {
          const form4Result = await this.$refs.form4.validate()

          if (!form4Result) {
            console.log('Validation failed.')
            return
          }

          this.parent.applicationStep = 7
        } catch (error) {
          console.error('Error in changing steps', error)
        }
      },
      localCurrency,
      formatCurrency,
      async makeBankPayment (data) {
        this.parent.bankDetails = data
        const { isValid, response, message } = await validateACH({ routing: data.routing, number: data.number, })
        if (isValid) {
          this.parent.paymentMethod.error = false
          this.parent.paymentMethod.message = null
          await this.makeCATermACHPayment()
        } else {
          this.parent.paymentMethod.error = true
          this.parent.paymentMethod.message = message
        }
      },
      async makeCATermACHPayment () {
        try {
          await this.$store.dispatch('makeCATermACHPayment', {
            createCustomerPayload: this.parent.createCustomerPayload,
            storeCATermACHPayload: this.parent.bankDetails,
            subscribeCATermPayload: this.parent.subscribeCATermPayload,
            bindPolicyPayload: this.parent.bindPolicyPayload
          })
          if (!this.parent.error && this.$store.getters.getCATermPaymentSuccessful) {
            this.parent.bindPolicyPayload.bankName = this?.parent.bankDetails?.bank ?? ''
            this.parent.bindPolicyPayload.accountNumber = parseInt(this?.parent.bankDetails?.number?.toString()?.slice(-4)) ?? ''
            this.parent.bindPolicyPayload.accountType = this?.parent.bankDetails?.type ?? ''
            await this.$store.dispatch('CATermBindPolicy', this.parent.bindPolicyPayload)
            if (!this.parent.error) {
              await this.$store.dispatch(
                'sendCATermPolicyPdfWithTemplateId',
                {
                  firstname: this.parent.user.firstName,
                  partnerId: this.parent.partnerID
                }
              )
              this.parent.applicationStep++
              gtag_report_conversion(this.$gtag, process.env.VUE_APP_PAYMENT)

              this.parent.isPolicyPuchased = true
            }
          }
        } catch (error) {
          console.error(error)
        }
      },
      async updateCardDetails (data) {
        this.parent.cardDetails = data
        const { isValid, response, message } = await validateCC({ ...data })
        if (isValid) {
          this.parent.paymentMethod.error = false
          this.parent.paymentMethod.message = null
          await this.makeCATermCreditCardPayment()
        } else {
          this.parent.paymentMethod.error = true
          this.parent.paymentMethod.message = message
        }
      },
      async makeCATermCreditCardPayment () {
        try {
          await this.$store.dispatch('makeCATermCreditCardPayment', {
            createCustomerPayload: this.parent.createCustomerPayload,
            storeCATermCreditCardPayload: this.parent.storeCATermCreditCardPayload,
            subscribeCATermPayload: this.parent.subscribeCATermPayload,
            bindPolicyPayload: this.parent.bindPolicyPayload
          })

          if (!this.parent.error && this.$store.getters.getCATermPaymentSuccessful) {
            await this.$store.dispatch('CATermBindPolicy', this.parent.bindPolicyPayload)
            if (!this.parent.error) {
              await this.$store.dispatch(
                'sendCATermPolicyPdfWithTemplateId',
                {
                  firstname: this.parent.user.firstName,
                  partnerId: this.parent.partnerID
                }
              )
              this.parent.applicationStep++
              this.parent.isPolicyPuchased = true
              gtag_report_conversion(this.$gtag, process.env.VUE_APP_PAYMENT)
            }
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
  }
</script>
