<template>
  <v-stepper-content step="4">
    <v-card
      min-height="300"
      elevation="0"
    >
      <div
        class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
      >
        {{ $t("personalStatement") }}
      </div>
      <div class="px-0">
        <v-row class="justify-center">
          <v-col
            cols="11"
            md="10"
          >
            <v-card
              class="pa-4"
              elevation="6"
            >
              <v-row>
                <v-col
                  class="text-h4 ma-2 font-weight-bold"
                >
                  {{ $t("i") }}, {{ capitalizedFirstName }} {{ capitalizedLastName }}
                </v-col>
              </v-row>
              <div>
                <v-checkbox
                  v-for="(term, index) in filteredTerms"
                  :key="index"
                  v-model="parent.selectedTerms[index]"
                  :label="term.replaceAll('%maxAmount%', formatCurrency(parent.maxCoverageAmount,'USD',$i18n.locale, true))"
                  class="font-weight-thin"
                  :name="'termCheckbox' + index"
                ></v-checkbox>
              </div>
              <v-card-actions class="flex-on-mobile  d-flex justify-center">
                <v-btn
                  x-large
                  class="white--text font-weight-bold style-back"
                  @click="parent.back"
                >
                  {{ $t('common.buttons.back') }}
                </v-btn>
                <v-btn
                  x-large
                  color="primary"
                  class="font-weight-bold"
                  :disabled="!allTermsAgreed"
                  name="continue4"
                  @click="navigateToBeneficiaryPage"
                >
                  {{ $t('common.buttons.agree') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-stepper-content>
</template>

<script>
  import i18n from '@/i18n'
  import { localCurrency } from '@/utils/common'
  import { formatCurrency } from '@/i18nValidate'
  export default {
    props: ['parent'],
    computed: {
      TERMS: () => i18n.t('caTerm.terms'),
      filteredTerms () {
        return this.setTerms()
      },
      capitalizedFirstName () {
        return this.parent.user.firstName?.charAt(0)?.toUpperCase() + this.parent.user.firstName?.slice(1)
      },
      capitalizedLastName () {
        return this.parent.user.lastName?.charAt(0)?.toUpperCase() + this.parent.user.lastName?.slice(1)
      },
      allTermsAgreed () {
        return this.parent.selectedTerms.every((term) => term)
      },
      currentAddress () {
        return this.$store.getters.getCurrentAddress
      },
    },
    watch: {
      currentAddress (val) {
        this.setTerms()
        this.parent.selectedTerms = this.filteredTerms.map(() => false)
      },
      filteredTerms () {
        this.parent.selectedTerms = this.filteredTerms.map(() => false)
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.$emit('step-ref', this)
        this.parent.selectedTerms = this.filteredTerms.map(() => this.parent.selectedTerms.length > 0)
      })
    },
    methods: {
      async navigateToBeneficiaryPage () {
        await this.getDecision()
        if (this.parent.decision === 'Declined') {
          return
        }
        this.parent.applicationStep++
      },
      setTerms () {
        if (this.parent.language === 'en' && this?.parent.user?.address?.province?.toLowerCase() === 'qc') {
          return this.TERMS
        } else {
          return this.TERMS.slice(0, -1)
        }
      },
      localCurrency,
      formatCurrency,
      async getDecision () {
        await this.$store.dispatch('getCATermDecision', this.parent.decisionPayload)
      }
    }
  }
</script>
